<template>
    <div class="qa-detail">
        <md-card v-if="qa">
            <!-- Question -->
            <md-card-header>
                <div class="left">
                    <h3 class="md-title">
                        {{qa.question}}
                        <md-chip class="md-accent" v-if="qa.private">Private</md-chip>
                    </h3>

                    <p>
                        <strong>{{qa.user_name}}</strong>
                        {{qa.createAt |date}}
                    </p>

                    <p>{{qa.content}}</p>
                </div>
                <div class="right">
                    <md-input-container>
                        <label for>Status</label>
                        <md-select :value="qa.status" @change="handleStatusChange">
                            <md-option value="OPEN">OPEN</md-option>
                            <md-option value="ANSWERED">ANSWERED</md-option>
                            <md-option value="CLOSED">CLOSED</md-option>
                        </md-select>
                    </md-input-container>
                </div>
            </md-card-header>

            <!-- Dialogs -->
            <md-card-content>
                <md-list>
                    <template v-for="(item,ix) in qa.dialogs">
                        <md-list-item :key="ix" v-if="ix">
                            <div class="reply-item">
                                <div class="reply-content-container">
                                    <strong class="reply-user">{{item.user_name}}:</strong>
                                    <pre>{{item.content}}</pre>
                                </div>
                                <div class="reply-timestamp">{{item.updatedAt |date}}</div>
                            </div>
                        </md-list-item>
                    </template>
                </md-list>
                <!-- reply textarea -->
                <div class="reply-textarea" v-if="canReply">
                    <md-input-container>
                        <label>Reply:</label>
                        <md-textarea placeholder="type your reply..." v-model="replyContent"></md-textarea>
                    </md-input-container>
                    <md-button @click="postReply" class="md-primary md-raised">Submit</md-button>
                </div>
            </md-card-content>
        </md-card>
    </div>
</template>
<script>
import moment from "moment";
import { mapGetters } from "vuex";
export default {
    props: {
        //qa_id
        id: { type: String }
    },
    data: function() {
        return {
            qa: null,
            replyContent: ""
        };
    },
    computed: {
        ...mapGetters({
            user: "user/user",
            hasPermission: "user/hasPermission"
        }),
        canReply() {
            if (this.user) {
                if (this.user._id == this.qa.user_id) {
                    return true;
                }
                if (this.user.role == "staff" && !this.hasPermission("reply_qa")) {
                    return false;
                }
                return true;
            }
            return false;
        }
    },
    methods: {
        async getQA() {
            let result = await this.$store.dispatch("crud/get", {
                api: `qas/${this.id}`
            });
            this.qa = result;
        },
        // post reply/question
        async postReply() {
            if (this.replyContent === "") {
                await this.$store.commit("setMessage", "Please Input Your Reply");
                return;
            }
            await this.$store.dispatch("crud/post", {
                api: `qas/reply/${this.id}`,
                data: { content: this.replyContent }
            });
            this.replyContent = "";
            this.$store.commit("setMessage", "Post Success");
            await this.getQA();
        },

        async handleStatusChange(val) {
            console.info(val);
            if (this.qa && val != this.qa.status) {
                await this.$store.dispatch("crud/put", {
                    api: `qas/${this.id}`,
                    data: {
                        status: val
                    }
                });
                this.$store.commit("setMessage", "Update Status Success");
            }
        }
    },
    async created() {
        await this.getQA();
    }
};
</script>
<style lang="less">
.qa-detail {
    .md-card {
        .md-card-header {
            display: flex;

            .left {
                flex: 1;
                padding-left: 10px;
            }
        }
    }

    .md-chip {
        vertical-align: middle;
    }

    .reply-item {
        width: 100%;
        box-sizing: border-box;
        border-radius: 5px;
        border: 1px solid #eee;
        margin-bottom: 15px;
        padding-left: 10px;

        pre {
            margin: 0 10px;
            color: rgba(0, 0, 0, 0.54);
            line-height: 20px;
            white-space: pre-wrap;
        }

        .reply-content-container {
            display: flex;
            margin: 5px;
        }

        .reply-user {
            color: #b68f56;
        }

        .reply-timestamp {
            margin: 5px;
            font-size: 12px;
            text-align: right;
            margin-right: 10px;
            color: rgba(0, 0, 0, 0.54);
        }
    }

    .reply-textarea {
        padding: 16px;
    }
}
</style>


