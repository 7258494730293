<template>
    <div class="qa-list">
        <list :apiList="apiList" :fs="qaListFields" :showDelete="showDelete" apiRemove="qas" title="Ticket List">
            <template slot="list-action" slot-scope="{ item }">
                <md-button @click="goDetail(item._id)" class="md-icon-button">
                    <md-icon>find_in_page</md-icon>
                </md-button>
            </template>
        </list>
    </div>
</template>
<script>
import List from "@/components/List.vue";
import QADetailModal from "@/components/QA/QADetailModal.vue";
import { qaListFields } from "@/config.js";
import { mapGetters, mapActions } from "vuex";

export default {
    props: {
        my: {
            type: Boolean,
            default: false
        }
    },
    components: {
        List,
        QADetailModal
    },
    computed: {
        ...mapGetters({
            user: "user/user",
            hasRole: "user/hasRole"
        }),
        qaListFields() {
            let fs = JSON.parse(JSON.stringify(qaListFields));
            fs = fs.map(f => {
                if (f.data == "order_no") {
                    if (this.my == true) {
                        f.link = "/admin/orders/detail/:order_id";
                    }
                }

                if (f.data == "question") {
                    if (this.my == true) {
                        f.link = "/admin/qas/my/detail/:_id";
                    }
                }

                return f;
            });
            return fs;
        },
        apiList() {
            let { user, my } = this;
            if (!my) {
                if (user.role === "staff") {
                    return `qas?vendor_id=${user.staff_vendor_id}`;
                } else if (user.role === "vendor") {
                    return `qas?vendor_id=${user._id}`;
                } else if (user.role === "manager" || user.role === "admin") {
                    return `qas`;
                }
            } else {
                return `qas?user_id=${user._id}`;
            }
        },
        showDelete() {
            return this.user.role === "manager" || this.user.role === "admin";
        }
    },

    methods: {
        async DeleteQA(id) {
            let result = await this.$store.dispatch("crud/delete", {
                api: `qas/${id}`
            });
            this.orders = result.data;
            this.page = result.page;
        },
        goDetail(id) {
            if (this.my) {
                this.$router.push(`/admin/qas/my/detail/${id}`);
            } else {
                this.$router.push(`/admin/qas/detail/${id}`);
            }
        },
        openModal(id) {}
    },
    created() {}
};
</script>
