<template>
    <div class="qa-edit">
        <edit :fs="qaEditFields" :id="id" @saved="saved" api="qas" title="Edit QA"></edit>
    </div>
</template>
<script>
// used to set fields of private status question
import { qaEditFields } from "@/config.js";
import Edit from "@/components/Edit";

export default {
    props: {
        id: {
            type: String
        }
    },
    components: {
        Edit
    },
    computed: {
        qaEditFields() {
            return qaEditFields;
        }
    },
    methods: {
        saved() {
            this.$router.go(-1);
        }
    }
    // methods: {
    // }
};
</script>
