<template>
    <!-- A Modal To show QAdetail -->
    <div class="qa-detail-modal">
        <md-dialog md-close-to="#qaDetailModal" md-open-from="#qaDetailModal" ref="qaDetailModal">
            <md-dialog-title>Question</md-dialog-title>
            <md-dialog-content>
                <div class="detail-wrapper">
                    <detail :id="id" v-if="id"></detail>
                </div>
            </md-dialog-content>
        </md-dialog>
    </div>
</template>
<script>
// dialog product detail
// will be used in qaproduct.vue & qalist.vue
import detail from "@/components/QA/QADetail.vue";
export default {
    props: ["id"],
    methods: {
        open(ref) {
            this.$refs[ref].open();
        },
        close(ref) {
            this.$refs[ref].close();
        },
        onOpen() {
            console.log("open !");
        },
        onClose(type) {
            if (type === "ok") {
            }
        }
    },
    created() {},
    beforeDestroy() {},
    components: {
        detail
    }
};
</script>
<style  lang="less">
.qa-detail-modal {
    .detail-wrapper {
        overflow: scroll;
    }
}
</style>


